















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Pagination extends Vue {
  @Prop() currentPage
  @Prop() totalUsers
  @Prop() perPage
  @Prop() onPageChange

  get paginationMin() {
    return (this.currentPage - 1) * this.perPage + 1
  }

  get paginationMax() {
    return this.currentPage * this.perPage > this.totalUsers ? this.totalUsers : this.currentPage * this.perPage
  }
}
